
import CategoriesMenuItems from './../navbar/CategoriesMenuItems';
import BDropdownHover from './../BDropdownHover';

export default {
  name: 'Megamenu2',
  components: {
    CategoriesMenuItems,
    BDropdownHover,
  },
  data() {
    return {
      openMenuIndex: null,
    };
  },
  props: {
    megamenu: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  mounted() {
    document.addEventListener('click', this.handleCloseMenu);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleCloseMenu);
  },
  methods: {
    getMenuItemClass(item, index) {
      const menuType = this.megamenu.openMegaMenu === 'click' ? 'megamenu-item-click' : 'megamenu-item';

      return [
        menuType,
        'text-nowrap',
        item.class || 'mx-auto px-3',
        { 'megamenu-show': this.openMenuIndex === index },
      ];
    },
    toggleMenu(index) {
      this.openMenuIndex = this.openMenuIndex === index ? null : index;
    },
    handleMegaMenuContent(event) {
      const { target } = event;
      if (target?.tagName === 'A') {
        this.closeMenu();
      }
    },
    handleCloseMenu(event) {
      if (!this.$el.contains(event.target)) {
        this.closeMenu();
      }
    },
    closeMenu() {
      if (this.openMenuIndex !== null) {
        this.openMenuIndex = null;
      }
    },
    validHtml(html) {
      try {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.innerHTML;
      } catch {
        return html;
      }
    },
  },
};
